.top-header {
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 9999;

  .navbar-brand {
    max-width: calc(100% - 72px);

    h1 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.pool-banner {
  position: relative;

  img {
    max-height: calc(100vh - 75px);
    object-fit: cover;
  }
}

.pool-banner-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 44;
  padding: 0 5%;
}

.pool-banner-caption-text {
  color: #fff;
  text-shadow: 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.whatsapp-btn {
  white-space: nowrap;
}

.whatsapp-btn,
.pool-banner-caption-btn .btn-lg {
  background-color: #040575;
  border-color: #040575;
  margin: 3px 10px;
}

.pool-social-links {
  position: fixed;
  top: calc(50% - 110px);
  right: 20px;
  transform: translateY(calc(-50% + 110px));
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;

  img {
    width: 40px;
    margin: 0 5px;
  }
}

.navbar-light .dropdown-item:hover:not(:active) {
  color: var(--primary);
}

.why-us {
  img {
    height: 100% !important;
    object-fit: cover;
  }
}

.why-us .accordion {
  padding: 0 100px 60px 100px;
}

.why-us .accordion .accordion-item + .accordion-item {
  margin-top: 15px;
}

.why-us .accordion .accordion-item {
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion button {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.why-us .accordion-button span {
  color: #0880e8;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-body p {
  margin-bottom: 0;
}

.why-us .accordion-button.collapsed::after {
  color: #343a40;
}

.why-us .accordion--button.collapsed::after:hover {
  color: #0880e8;
}

@media (max-width: 1024px) {
  .why-us .accordion {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .accordion {
    padding-bottom: 30px;
  }
}
